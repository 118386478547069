@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir/AvenirLT-Roman.eot');
  src: local('Avenir LT 55 Roman'), local('AvenirLT-Roman'),
    url('../../fonts/Avenir/AvenirLT-Roman.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Avenir/AvenirLT-Roman.woff') format('woff'),
    url('../../fonts/Avenir/AvenirLT-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir/AvenirLT-MediumOblique.eot');
  src: local('Avenir LT 65 Medium Oblique'),
    local('AvenirLT-MediumOblique'),
    url('../../fonts/Avenir/AvenirLT-MediumOblique.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Avenir/AvenirLT-MediumOblique.woff')
      format('woff'),
    url('../../fonts/Avenir/AvenirLT-MediumOblique.ttf')
      format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir/AvenirLT-Heavy.eot');
  src: local('Avenir LT 85 Heavy'), local('AvenirLT-Heavy'),
    url('../../fonts/Avenir/AvenirLT-Heavy.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Avenir/AvenirLT-Heavy.woff') format('woff'),
    url('../../fonts/Avenir/AvenirLT-Heavy.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir/AvenirLT-Black.eot');
  src: local('Avenir LT 95 Black'), local('AvenirLT-Black'),
    url('../../fonts/Avenir/AvenirLT-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Avenir/AvenirLT-Black.woff') format('woff'),
    url('../../fonts/Avenir/AvenirLT-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir/AvenirLT-LightOblique.eot');
  src: local('Avenir LT 35 Light Oblique'),
    local('AvenirLT-LightOblique'),
    url('../../fonts/Avenir/AvenirLT-LightOblique.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Avenir/AvenirLT-LightOblique.woff')
      format('woff'),
    url('../../fonts/Avenir/AvenirLT-LightOblique.ttf')
      format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir/AvenirLT-BlackOblique.eot');
  src: local('Avenir LT 95 Black Oblique'),
    local('AvenirLT-BlackOblique'),
    url('../../fonts/Avenir/AvenirLT-BlackOblique.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Avenir/AvenirLT-BlackOblique.woff')
      format('woff'),
    url('../../fonts/Avenir/AvenirLT-BlackOblique.ttf')
      format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir/AvenirLT-HeavyOblique.eot');
  src: local('Avenir LT 86 Heavy Oblique'),
    local('AvenirLT-HeavyOblique'),
    url('../../fonts/Avenir/AvenirLT-HeavyOblique.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Avenir/AvenirLT-HeavyOblique.woff')
      format('woff'),
    url('../../fonts/Avenir/AvenirLT-HeavyOblique.ttf')
      format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir/AvenirLT-Medium.eot');
  src: local('Avenir LT 65 Medium'), local('AvenirLT-Medium'),
    url('../../fonts/Avenir/AvenirLT-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Avenir/AvenirLT-Medium.woff') format('woff'),
    url('../../fonts/Avenir/AvenirLT-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir/AvenirLT-Light.eot');
  src: local('Avenir LT 35 Light'), local('AvenirLT-Light'),
    url('../../fonts/Avenir/AvenirLT-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Avenir/AvenirLT-Light.woff') format('woff'),
    url('../../fonts/Avenir/AvenirLT-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir/AvenirLT-Book.eot');
  src: local('Avenir LT 45 Book'), local('AvenirLT-Book'),
    url('../../fonts/Avenir/AvenirLT-Book.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Avenir/AvenirLT-Book.woff') format('woff'),
    url('../../fonts/Avenir/AvenirLT-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir/AvenirLT-Oblique.eot');
  src: local('Avenir LT 55 Oblique'), local('AvenirLT-Oblique'),
    url('../../fonts/Avenir/AvenirLT-Oblique.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Avenir/AvenirLT-Oblique.woff') format('woff'),
    url('../../fonts/Avenir/AvenirLT-Oblique.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir/AvenirLT-BookOblique.eot');
  src: local('Avenir LT 45 Book Oblique'),
    local('AvenirLT-BookOblique'),
    url('../../fonts/Avenir/AvenirLT-BookOblique.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Avenir/AvenirLT-BookOblique.woff') format('woff'),
    url('../../fonts/Avenir/AvenirLT-BookOblique.ttf')
      format('truetype');
  font-weight: normal;
  font-style: italic;
}
