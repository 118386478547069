@each $state, $color in $colors-map {
  .btn-#{$state} {
    background-color: $color;
    min-height: 40px;
    border: none;
    border-radius: 2px;
    color: $theme-color;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:disabled {
      cursor: default;
      color: $disable-color;
      background-color: $secondary-color;
    }
  }
}

/*-------grid-------*/

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}

@mixin column($gutter) {
  width: 100%;
  padding-right: ceil(($gutter / 2));
  padding-left: floor(($gutter / 2));
}

@mixin container($gutter) {
  @include column($gutter);
  margin-right: auto;
  margin-left: auto;
  @each $point, $container-max-width in $container-max-widths {
    @include breakpoint($point) {
      max-width: $container-max-width;
    }
  }
}

@mixin row($gutter) {
  margin-right: floor(($gutter / -2));
  margin-left: ceil(($gutter / -2));
  display: flex;
  flex-wrap: wrap;
}

@mixin col($gutter, $columns-count) {
  @for $i from 1 through ($columns-count) {
    .col-#{$i} {
      @include column($gutter);
      max-width: (($i * 100%) / $columns-count);
      flex: 0 0 (($i * 100%) / $columns-count);
      min-width: (($i * 100%) / $columns-count);
    }
  }
  @each $point, $breakpoint in $breakpoints {
    .col-#{$point} {
      @media (min-width: $breakpoint) {
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
      }
    }
  }
  @each $point, $breakpoint in $breakpoints {
    @if ($point) {
      @for $i from 1 through ($columns-count) {
        .col-#{$point}-#{$i} {
          @include column($gutter);
          @media (min-width: $breakpoint) {
            max-width: (($i * 100%) / $columns-count);
            flex: 0 0 (($i * 100%) / $columns-count);
            min-width: (($i * 100%) / $columns-count);
          }
        }
      }
    }
  }
}

@mixin offset($columns-count) {
  @for $i from 1 through ($columns-count) {
    .offset-#{$i} {
      margin-left: (($i * 100%) / $columns-count);
    }
  }
  @each $point, $breakpoint in $breakpoints {
    @if ($point) {
      @for $i from 1 through ($columns-count) {
        .offset-#{$point}-#{$i} {
          @media (min-width: $breakpoint) {
            margin-left: (($i * 100%) / $columns-count);
          }
        }
      }
    }
  }
}

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
