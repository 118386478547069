.gallery {
  margin-top: 5px;
  transition: max-height 0.5s ease-in;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 47px);
  z-index: 10;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  @include themify($themes) {
    background-color: themed('gallery');
  }

  &.is-opened {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    transition: max-height 0.5s ease-in;
  }

  @media (min-width: $breakpoint-md) {
    width: 100%;
    height: 228px;
    position: relative;
    z-index: 1;
    border-radius: 0;
  }

  @media (min-width: $breakpoint-lg) {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    border-top: 1px solid;
    height: 170px;
    margin-top: 0;

    @include themify($themes) {
      border-color: themed('galleryBorder');
    }
  }
}

.gallery-header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include themify($themes) {
    border-color: themed('galleryBorder');
  }

  @media (min-width: $breakpoint-md) {
    border-bottom: 1px solid;
  }
}

.gallery-header-title {
  display: block;
  font-size: 12px;
  font-weight: normal;
  margin-right: 12px;

  @include themify($themes) {
    color: themed('text');
  }
}

.gallery-header-btn {
  margin-left: 12px;
  min-height: unset;
  height: 27px;
  font-size: 12px;
  font-weight: normal;
  padding: 5px 10px;
  display: none;

  @include themify($themes) {
    background-color: themed('btnGallery');
  }

  &.delete {
    color: $danger-color;
  }

  @media (min-width: $breakpoint-md) {
    display: flex;
  }
}

.gallery-header-icon {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}

.gallery-body {
  padding: 15px 12px;
  max-width: 100%;
  overflow-x: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 25px;

  .gallery.is-opened > & {
    overflow-y: auto;
    max-height: 90%;
  }

  @media (min-width: $breakpoint-md) {
    display: flex;
    padding: 25px 15px;
    column-gap: 15px;
    row-gap: 25px;

    .gallery.is-opened > & {
      display: grid;
      justify-content: center;
      align-items: center;
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }

  @media (min-width: $breakpoint-lg) {
    padding: 15px 10px 25px 10px;

    .gallery.is-opened > & {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
  }

  @media (min-width: $breakpoint-xl) {
    padding: 15px 10px 25px 10px;

    .gallery.is-opened > & {
      grid-template-columns: repeat(9, minmax(0, 1fr));
    }
  }
}

.gallery-drag {
  position: relative;
  width: 120px;
  min-width: 120px;
  height: 100px;
  border-radius: 2px;
  border: 1px dashed #c7c7c7;
  padding: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .gallery-body.is-opened > & {
    width: 100%;
    min-width: unset;
  }

  &.is-active {
    border: 1px dashed #edc43d;
  }

  @media (min-width: $breakpoint-lg) {
    height: 80px;
  }
}

.gallery-drag-input {
  opacity: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
}

.gallery-drag-text {
  display: block;
  font-size: 10px;
  font-weight: 500;
  text-align: center;

  @include themify($themes) {
    color: themed('text');
  }
}

.gallery-drag-link {
  display: block;
  font-size: 10px;
  margin-top: 1px;
  font-weight: normal;
  color: #9b9ca9;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.gallery-cart {
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  top: -3px;
  left: -3px;
  border: 1px solid #9d9d9d;
  border-radius: $border-radius;
  padding: 4px 6px;
  font-size: 10px;
  font-weight: normal;

  @include themify($themes) {
    background-color: themed('gallery');
    color: themed('text');
  }

  &::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include themify($themes) {
      background-image: themed('iconCart');
    }
  }
}

.gallery-img-container {
  width: 100%;
  height: 100%;

  .gallery-card:not(.multiple):not(.multi-selected):hover &,
  .gallery-card.selected & {
    border: 1px solid;
    border-radius: 6px;
    cursor: pointer;

    @include themify($themes) {
      border-color: themed('mainColor');
    }
  }
}

.gallery-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;

  .gallery-card:not(.multiple):not(.multi-selected):hover &,
  .gallery-card.selected & {
    padding: 2px;
    border-radius: 6px;
  }
}

.gallery-card {
  width: 120px;
  min-width: 120px;
  height: 100px;
  position: relative;
  cursor: pointer;

  @media (min-width: $breakpoint-lg) {
    height: 80px;
  }

  .gallery-body.is-opened > & {
    width: 100%;
    min-width: unset;
  }

  &.multiple,
  &.multi-selected {
    &::after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      border: 1px solid #9d9d9d;
      border-radius: 50%;
      position: absolute;
      top: -3px;
      right: -3px;

      @include themify($themes) {
        background-color: themed('gallery');
      }
    }
  }

  &.multi-selected {
    &::after {
      border-color: $main-color;
    }

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      background-color: $main-color;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }
}

.gallery-card-title {
  display: block;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  margin-top: 5px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  @include themify($themes) {
    color: themed('text');
  }
}

.gallery-header-controls {
  display: none;
  align-items: center;
  margin-right: 12px;

  @media (min-width: $breakpoint-md) {
    display: flex;
  }
}

.gallery-controls-icon {
  width: 12px;
  height: 12px;
  margin-left: 18px;
  cursor: pointer;
}

.gallery-close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 14px;
  top: 12px;
  cursor: pointer;

  @media (min-width: $breakpoint-md) {
    display: none;
  }
}

.gallery-arrow {
  .gallery.is-opened & {
    transform: rotate(180deg);
  }
}

.gallery-controls-btn {
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }
}

.gallery-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  padding: 10px 12px;
  gap: 12px;

  @media (min-width: $breakpoint-md) {
    display: none;
  }
}

.gallery-footer-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  min-height: unset;
  height: 35px;
}
