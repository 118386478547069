.confirm-ship {
  width: 68%;
  padding: 0 30px 12px 0;
  margin-left: auto;
  position: relative;
  line-height: 1.2;

  @media (min-width: $breakpoint-md) {
    width: 55%;
    padding: 25px 30px 15px 0;
    line-height: 1.5;
  }

  @media (min-width: $breakpoint-lg) {
    padding: 10px 30px 10px 0;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 20px 30px 20px 0;
  }
}

.confirm-info {
  display: block;
  font-size: 10px;
  font-weight: normal;
  line-height: 18px;

  @include themify($themes) {
    color: themed('text');
  }

  @media (min-width: $breakpoint-md) {
    font-size: 12px;
  }
}

.confirm-list {
  width: 100%;
  padding: 14px 10px;
  margin-top: auto;
  border-bottom: 1px solid;

  @include themify($themes) {
    background-color: themed('back');
    border-color: themed('cartBorder');
  }

  @media (min-width: $breakpoint-md) {
    width: 55%;
    margin-left: auto;
    margin-top: 12px;
    padding: 0;
    padding-bottom: 12px;

    @include themify($themes) {
      background-color: transparent;
    }
  }

  @media (min-width: $breakpoint-xl) {
    margin-top: 18px;
  }
}

.confirm-head {
  padding: 20px 0;
}

.confirm-title {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 900;

  &:first-child {
    margin-bottom: 10px;
  }

  @include themify($themes) {
    color: themed('tableTh');
  }
}

.confirm-subtitle {
  display: block;
  font-size: 12px;
  font-weight: 900;

  @include themify($themes) {
    color: themed('text');
  }
}
