.dropdown-container {
  position: relative;
}

.space {
  margin-bottom: 200px;

  @media (min-width: $breakpoint-md) {
    margin-bottom: 0;
  }
}

.dropdown-arrow-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  cursor: pointer;
}

.dropdown-arrow {
  width: 10px;
  height: 10px;
  transform: rotate(180deg);
}

.dropdown-arrow.is-active {
  transform: rotate(0deg);
}

.state-list {
  position: absolute;
  width: 100%;
  font-size: 14px;
  line-height: 1.2;
  padding: 5px 0;
  max-height: 195px;
  left: 0;
  top: 100%;
  overflow-y: auto;
  border: 1px solid #e8e8e8;
}

.state-item-wrapper {
  width: 100%;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #e8e8e8;
  }
}

.state-item {
  display: block;
  padding: 5px 14px;
  width: 100%;
}
