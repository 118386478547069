.pay-container {
  display: grid;
  align-items: baseline;
  overflow-x: hidden;

  @media (min-width: $breakpoint-md) {
    margin-top: 20px;
    grid-template-columns: 1fr 1fr;
    gap: 66px;
  }

  @media (min-width: $breakpoint-lg) {
    grid-template-columns: 0.54fr 1fr;
    gap: 25px;
  }

  @media (min-width: $breakpoint-xl) {
    margin-right: 8%;
    margin-top: 24px;
    grid-template-columns: 0.5fr 1fr;
    gap: 20px;
  }
}

.pay-card {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;

  @media (min-width: $breakpoint-md) {
    row-gap: 12px;
  }

  @media (min-width: $breakpoint-lg) {
    column-gap: unset;
    row-gap: 8px;
  }

  @media (min-width: $breakpoint-xl) {
    margin: 0 -10px;
    row-gap: 16px;
  }
}

.pay-check {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 14px;

  @media (min-width: $breakpoint-md) {
    margin-top: 10px;
  }

  @media (min-width: $breakpoint-xl) {
    margin-top: 0;
  }
}

.pay-check-label {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;

  @include themify($themes) {
    color: themed('text');
  }

  &::before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    border: 1px solid;
    margin-right: 11px;

    @include themify($themes) {
      border-color: themed('inputBorder');
    }
  }
}

.pay-check-input {
  &:checked ~ .pay-check-label {
    &::before {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 14px;

      @include themify($themes) {
        background-image: themed('iconCheck');
      }
    }
  }
}

.pay-col {
  padding: 0 16px;
  margin-bottom: 9px;

  &.pay-check-label {
    padding-right: 16px;
    padding-left: 0;

    @media (min-width: $breakpoint-lg) {
      padding: 0 16px;
    }

    @media (min-width: $breakpoint-xl) {
      padding: 0 10px;
    }
  }

  @media (min-width: $breakpoint-md) {
    margin-bottom: 0;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 0 10px;
  }

  .dropdown-text {
    font-size: 11px;
    font-weight: 500;

    @media (min-width: $breakpoint-md) {
      font-size: 14px;
      font-weight: normal;
    }
  }
}
