.e-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #313130;
  z-index: 2000;
  display: flex;
  flex-direction: column;
}

.e-modal-header {
  padding: 18px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.e-modal-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  flex: 1;

  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    justify-content: flex-end;
  }
}

.e-modal-btn {
  position: relative;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }

  &.medium {
    color: #ffc600;
  }

  &.danger {
    color: #f36262;
  }

  &.isActive {
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -6px;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: #ffc600;
    }
  }
}

.e-modal-icon {
  width: 19px;
  height: 19px;

  &.icon-e-times {
    width: 11px;
    height: 11px;
  }

  .e-modal-controls & {
    width: 17px;
    height: 17px;
  }
}

.e-modal-footer {
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
}

.e-modal-dpi {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: fit-content;
  padding: 0 8px;
  background-color: #fff;
  border-radius: 2px;
  font-size: 9px;
  color: #2c2e45;
  text-transform: uppercase;
  z-index: 1;
}

.e-modal-controls {
  justify-content: space-around;
}
