.step-controls {
  width: 100%;
  margin-top: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 5px 7px;
  margin-bottom: 5px;

  .cart-empty & {
    border: none;
  }

  &.option-list {
    padding: 14px 16px;
    margin: 0;
    justify-content: flex-end;
  }

  @include themify($themes) {
    border-color: themed('cartBorder');
    background-color: themed('gallery');

    @media (min-width: $breakpoint-lg) {
      background-color: unset;
    }
  }

  @media (min-width: $breakpoint-md) {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    padding-top: 22px;
  }

  @media (min-width: $breakpoint-lg) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0 0 0;
    margin-bottom: 0;
    border-top: 1px solid;
  }

  @media (min-width: $breakpoint-xl) {
    padding-top: 22px;
  }
}

.step-controls-btn {
  font-size: 10px;
  padding: 10px;
  text-transform: uppercase;
  min-height: unset;
  width: 100%;
  white-space: nowrap;

  // &:last-child {
  //   margin-left: auto;
  // }

  &.btn-secondary {
    @include themify($themes) {
      background-color: themed('btnGallery');
    }
  }

  @media (min-width: $breakpoint-md) {
    font-size: 13px;
    padding: 10px 22px;
    text-transform: none;
    width: auto;
  }

  &.right-side {
    margin-left: auto;
  }

  &.left-side {
    margin-right: auto;
  }

  &.btn-secondary {
    @include themify($themes) {
      background-color: themed('btnGallery');
    }
  }

  @media (min-width: $breakpoint-md) {
    font-size: 13px;
    padding: 10px 22px;
    text-transform: none;
  }

  @media (min-width: $breakpoint-lg) {
    padding: 10px 20px;
    font-size: 12px;
    text-transform: unset;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 14px;
  }
}
