.dropdown {
  height: 37px;
  width: 100%;
  font-size: 14px;
  line-height: 1.2;
  position: relative;

  @include themify($themes) {
    color: themed('text');
  }
}

.dropdown-btn {
  border: 1px solid;
  height: 100%;
  width: 100%;
  padding: 10px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  text-align: left;
  cursor: pointer;

  .check-error & {
    @include themify($themes) {
      border-color: #da7d82;
    }
  }

  @include themify($themes) {
    border-color: themed('inputBorder');
    background-color: themed('gallery');
  }

  &:focus {
    outline: none;
  }
}

.dropdown-text {
  font-size: 14px;
  font-weight: normal;

  @include themify($themes) {
    color: themed('text');
  }
}

.dropdown-btn-icon {
  width: 10px;
  height: 10px;
  transform: rotate(180deg);

  .dropdown.is-active & {
    transform: rotate(0deg);
  }
}

.dropdown-group {
  display: none;
  width: 100%;
  border: 1px solid #e8e8e8;
  font-size: 14px;
  line-height: 1.2;
  padding: 5px 0;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 2;
  background-color: #fff;

  @include themify($themes) {
    background-color: themed('gallery');
  }

  &.is-active {
    display: block;
  }
}

.dropdown-item {
  padding: 5px 14px;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;

  @include themify($themes) {
    color: themed('text');
  }

  &:hover {
    @include themify($themes) {
      background-color: themed('inputBorder');
    }
  }
}

.dropdown-label {
  display: block;
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 900;

  @include themify($themes) {
    color: themed('tableTh');
  }
}
