.product-size-grid {
  display: flex;
  align-items: center;
  gap: 6px;
  overflow-x: auto;

  @media (min-width: $breakpoint-md) {
    order: 3;
  }

  @media (min-width: $breakpoint-lg) {
    gap: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    overflow: hidden;
  }

  @media (min-width: $breakpoint-xl) {
    gap: 6px;
  }
}

.product-size-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 2px;
  min-height: 50px;
  min-width: 50px;
  padding: 9px 2px;
  cursor: pointer;

  @include themify($themes) {
    border-color: themed('productBorder');
  }

  &:not(.is-disabled):hover,
  &.is-highlight {
    @include themify($themes) {
      border-color: themed('mainColor');
      box-shadow: 0 0 3px 0 themed('productShadow');
    }
  }

  &.is-active {
    @include themify($themes) {
      border-color: themed('mainColor');
    }
  }

  &.is-disabled {
    @include themify($themes) {
      border-color: themed('productDisable');
    }
  }

  @media (min-width: $breakpoint-lg) {
    width: auto;
  }
}

%product-size-text {
  font-size: 12px;
  font-weight: 500;
  display: block;
}

.product-size-scale {
  @extend %product-size-text;
  margin-bottom: 2px;

  @include themify($themes) {
    color: themed('text');
  }

  .product-size-card.is-disabled & {
    color: $disable-color;
  }
}

.product-size-price {
  @extend %product-size-text;
  color: $disable-color;

  .product-size-card.is-active & {
    @include themify($themes) {
      color: themed('mainColor');
    }
  }

  .product-size-card.is-disabled & {
    color: $disable-color;
  }
}

/*--------------------------------------*/

.product-grid {
  display: flex;
  gap: 13px;
  overflow-x: auto;
  margin-top: 23px;

  @media (min-width: $breakpoint-md) {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 20px;

    .product-category & {
      grid-template-columns: 1fr;
    }
  }

  @media (min-width: $breakpoint-lg) {
    gap: 4px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 0;
  }

  @media (min-width: $breakpoint-xl) {
    gap: 5px;
  }
}

.product-card-container {
  position: unset;
  overflow: hidden;
  min-width: 82px;
  width: 100%;

  &.is-animated {
    max-width: 30%;
  }

  @media (min-width: $breakpoint-md) {
    min-width: unset;
    width: 100%;
  }
}

.product-wrapper {
  position: relative;
  height: 100%;
}

.product-card {
  position: relative;
  overflow: hidden;
  cursor: pointer;

  .product-card-container.is-disable & {
    cursor: default;
  }
}

.product-card-mounted {
  display: block;
  position: relative;
  min-height: 56px;
  border: 1px solid;
  border-radius: 2px;
  padding: 10px;
  overflow: hidden;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (min-width: $breakpoint-md) {
    padding: 15px;
  }

  @include themify($themes) {
    border-color: themed('productBorder');
  }

  .product-card-container.is-active & {
    border-color: $main-color;
  }

  .product-card-container.is-disable & {
    @include themify($themes) {
      border-color: themed('productDisable');
    }
  }
}

%product-icon {
  display: none;
  position: absolute;
  right: -4px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  z-index: 2;
  background-size: 14px;
  cursor: pointer;

  @include themify($themes) {
    background-color: themed('sidebar');
  }

  @media (min-width: $breakpoint-xl) {
    width: 28px;
    height: 28px;
    background-size: 18px;
    right: -5px;
  }
}

.product-card-checked {
  @extend %product-icon;
  bottom: -4px;
  border: 1px solid #e6e6e6;

  @media (min-width: $breakpoint-xl) {
    top: -5px;
  }

  .product-card-container.is-disable & {
    display: block;

    @media (min-width: $breakpoint-xl) {
      display: none;
    }
  }

  .product-card-container.is-disable:hover & {
    display: block;
  }
}

.product-card-info {
  @extend %product-icon;
  bottom: -4px;
  border: 1px solid #c6c7d0;

  @media (min-width: $breakpoint-xl) {
    bottom: -5px;
  }

  .product-card-container:not(.is-active):not(.is-disable) & {
    display: block;

    @media (min-width: $breakpoint-xl) {
      display: none;
    }
  }

  .product-card-container:not(.is-active):not(.is-disable):hover & {
    display: block;
  }

  .product-card-container.is-mounted & {
    display: block;
  }

  &.is-active {
    border-color: $main-color;
  }
}

.product-card-img {
  position: relative;
  min-height: 56px;
  border: 1px solid;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Cg%3E%3Cg transform='translate(-1319.000000, -1290.000000) translate(1288.000000, 1274.000000) translate(32.000000, 17.000000)'%3E%3Cg fill='%23CACBD0' fill-rule='nonzero'%3E%3Cpath d='M5.764 4.615c-1.271 0-2.305-1.035-2.305-2.308C3.459 1.035 4.493 0 5.764 0 7.036 0 8.07 1.035 8.07 2.307c0 1.273-1.034 2.308-2.306 2.308zm0-3.461c-.636 0-1.152.518-1.152 1.153 0 .636.516 1.154 1.152 1.154.637 0 1.153-.518 1.153-1.154 0-.635-.516-1.153-1.153-1.153zM.169 11.945c-.225-.225-.225-.591 0-.816l2.64-2.642c.65-.653 1.8-.653 2.452 0l1.656 1.657 5.692-5.696c.65-.653 1.8-.653 2.452 0l.91.912c.225.225.225.59 0 .815-.225.225-.59.225-.815 0l-.91-.911c-.218-.217-.603-.217-.822 0l-6.099 6.103c-.225.225-.59.225-.815 0L4.447 9.302c-.218-.217-.603-.217-.822 0l-2.64 2.642c-.113.113-.26.17-.408.17-.148 0-.295-.057-.408-.17z' transform='translate(2.907143, 4.795351)'/%3E%3C/g%3E%3Crect width='22' height='22' stroke='%23CACBD0' stroke-width='1.2' rx='2.4'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");

  .product-category & {
    position: relative;
    width: 100%;
    padding-top: 50%;
    background-size: contain;
  }

  @include themify($themes) {
    border-color: themed('productBorder');
  }

  .product-card-container.is-disable & {
    opacity: 0.3;
  }

  .product-card-container.is-active & {
    @include themify($themes) {
      border-color: themed('mainColor');
    }
  }

  .product-card-container.is-disable & {
    @include themify($themes) {
      border-color: themed('productDisable');
    }
  }

  &.disabled {
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23c6c7d0' fill-rule='nonzero'%3E%3Cg%3E%3Cg%3E%3Cpath d='M5.5 0C2.462 0 0 2.462 0 5.5S2.462 11 5.5 11 11 8.538 11 5.5C10.997 2.464 8.536.003 5.5 0zM2.445 1.905C3.298 1.18 4.381.784 5.5.785c1.114 0 2.192.397 3.041 1.118l-6.64 6.64C.22 6.56.463 3.588 2.446 1.905zm6.11 7.19c-.853.724-1.936 1.121-3.055 1.12-1.114 0-2.192-.397-3.041-1.118l6.64-6.64c1.682 1.983 1.439 4.955-.544 6.638z' transform='translate(-1046.000000, -79.000000) translate(1037.000000, 70.000000) translate(9.239906, 9.535375)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  }

  &.default-image {
    background-size: 24px;

    @media (min-width: $breakpoint-lg) {
      background-size: 18px;
    }

    @media (min-width: $breakpoint-xl) {
      background-size: 24px;
    }
  }

  @media (min-width: $breakpoint-lg) {
    min-height: 50px;
  }

  @media (min-width: $breakpoint-xl) {
    min-height: 56px;
  }
}

%product-card-text {
  display: block;
  font-size: 11px;
  font-weight: 500;
  text-align: center;

  @media (min-width: $breakpoint-xl) {
    font-size: 12px;
  }
}

.product-card-title {
  @extend %product-card-text;
  line-height: 1.5;

  @include themify($themes) {
    color: themed('text');
  }

  .product-card-container.is-disable & {
    color: $disable-color;
  }

  @media (min-width: $breakpoint-lg) {
    margin-top: 10px;
  }
}

.product-card-price {
  @extend %product-card-text;
  color: $disable-color;
  margin-top: 2px;

  .product-card-container.is-active & {
    @include themify($themes) {
      color: themed('mainColor');
    }
  }
}

.is-animated {
  position: absolute;
}
