.err-message-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  border: 1px solid #edd3d5;
  padding: 18px 51px 18px 17px;
  min-height: 54px;
  width: 100%;
  display: flex;
  align-items: center;

  @include themify($themes) {
    background-color: themed('errorMessage');
  }

  @media (min-width: $breakpoint-lg) {
    top: 46px;
    width: 25%;
    right: 16px;
    border-radius: 4px;
  }

  @media (min-width: $breakpoint-xl) {
    top: 66px;
  }
}

.err-message {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #712e35;
  line-height: 1.21;
}

.err-message-btn {
  display: flex;
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translate(0, -50%);
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #712e35;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.err-message-icon {
  width: 14px;
  height: 14px;
}
