.switch-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 16px;

  @include themify($themes) {
    color: themed('text');
  }
}

.switch-input {
  display: none;
}

.switch-slider {
  width: 36px;
  height: 20px;
  min-width: 36px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: relative;
  right: 0;
  top: 0;
  transition: 0.4s;
  border-radius: 83px;

  @include themify($themes) {
    background-color: themed('switch');
  }

  &::before {
    background-color: #fff;
    left: 2px;
    top: 50%;
    transform: translate(0, -50%);
    content: '';
    position: absolute;
    transition: 0.4s;
    border-radius: 50%;
    width: 15px;
    height: 15px;

    .switch-input:checked + & {
      transform: translate(0, -50%) translateX(16px);
    }
  }

  .switch-input:disabled + & {
    opacity: 0.5;
    cursor: default;
  }

  .switch-input:checked + & {
    @include themify($themes) {
      background-color: themed('mainColor');
    }
  }
}
