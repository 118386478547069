.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;

  @include themify($themes) {
    background-color: themed('back');
  }

  @media (min-width: $breakpoint-md) {
    min-height: 57px;
    padding: 10px;
  }

  @media (min-width: $breakpoint-lg) {
    min-height: 40px;
    padding: 5px 10px;
  }

  @media (min-width: $breakpoint-xl) {
    min-height: 60px;
  }
}

.header-logo-container {
  @media (min-width: $breakpoint-md) {
    flex: 1 1 0;
    max-width: 100%;
    object-fit: contain;
    object-position: left;
  }

  @media (min-width: $breakpoint-lg) {
    flex: unset;
  }
}

.header-logo {
  height: 14px;
  width: 26px;
  margin-left: 9px;

  @media (min-width: $breakpoint-md) {
    margin-left: 6px;
    width: 35px;
    height: 20px;
  }

  @media (min-width: $breakpoint-lg) {
    width: 45px;
    height: 25px;
    margin-left: 0;
  }

  @media (min-width: $breakpoint-xl) {
    height: 45px;
    width: 50px;
    margin-left: 11px;
  }
}

.header-user {
  display: none;
  align-items: center;
  justify-content: center;

  @media (min-width: $breakpoint-md) {
    display: flex;
  }
}

.header-user-icon {
  width: 13px;
  height: 13px;
}

.header-user-title {
  display: block;
  margin: 0 8px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;

  @include themify($themes) {
    color: themed('text');
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 14px;
  }
}

.header-user-number {
  display: block;
  font-size: 12px;
  font-weight: 900;

  @include themify($themes) {
    color: themed('text');
  }

  @media (min-width: $breakpoint-lg) {
    font-size: 14px;
  }
}

.header-cart-icon {
  width: 18px;
  height: 18px;

  @media (min-width: $breakpoint-lg) {
    width: 16px;
    height: 16px;
  }

  @media (min-width: $breakpoint-xl) {
    width: 18px;
    height: 18px;
  }
}

.header-cart {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 5px;
  padding: 0;

  @media (min-width: $breakpoint-xl) {
    margin-left: 10px;
  }
}

.header-info-btn {
  @media (min-width: $breakpoint-md) {
    padding: 10px 15px;
  }
}

.header-info-btn,
.header-cart {
  min-width: 37px;
  height: 37px;
  min-height: unset;

  @media (min-width: $breakpoint-lg) {
    height: 30px;
    width: auto;
    min-width: 30px;
  }

  @media (min-width: $breakpoint-xl) {
    height: 40px;
    min-width: 37px;
  }
}

.header-btn-icon {
  margin-right: 0;

  @media (min-width: $breakpoint-md) {
    margin-right: 11px;
  }

  @media (min-width: $breakpoint-lg) {
    margin-right: 7px;
  }
}

.header-btn-text {
  display: none;

  @media (min-width: $breakpoint-md) {
    display: block;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }

  @media (min-width: $breakpoint-lg) {
    font-size: 11px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 14px;
  }
}

.header-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-cart-count {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 4px;
  right: 4px;
  border: 1px solid $theme-color;
  border-radius: 50%;
  height: 12px;
  min-width: 12px;
  padding: 0 2px;
  font-size: 8px;
  color: $theme-color;

  @media (min-width: $breakpoint-lg) {
    top: 0;
    right: 0;
    padding: 0 1px;
  }

  @media (min-width: $breakpoint-xl) {
    top: 5px;
    right: 3px;
    padding: 0 2px;
  }
}
