.footer {
  padding: 5px 0;
  position: relative;
  background-color: $theme-color;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include themify($themes) {
    background-color: themed('footer');
  }

  @media (min-width: $breakpoint-md) {
    padding: 5px 10px;
  }

  @media (min-width: $breakpoint-lg) {
    height: 40px;
    padding: 5px 10px;
    min-height: unset;
  }

  @media (min-width: $breakpoint-xl) {
    height: 50px;
  }
}

.footer-btn {
  background-color: unset;
  border: none;
  min-width: 35px;
  height: 35px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 0;
  margin-left: 5px;
  padding: 8px;

  &:last-child {
    margin-right: 5px;
  }

  &:enabled {
    cursor: pointer;
  }

  @media (min-width: $breakpoint-md) {
    min-width: 24px;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  @media (min-width: $breakpoint-lg) {
    margin: 0;
    padding: 0;
    min-width: 24px;
    background-color: transparent;

    &:last-child {
      margin: 0;
    }
  }

  @media (min-width: $breakpoint-xl) {
    min-width: 26px;
  }
}

.footer-btn-title {
  display: none;
  margin-left: 5px;
  color: $default-color;

  @media (min-width: $breakpoint-lg) {
    display: block;
  }
}

.footer-icon {
  width: 100%;
  height: 100%;
}

.footer-controls {
  display: flex;
  align-items: center;
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.footer-controls-title {
  display: block;
  font-size: 12px;
  font-weight: normal;
  color: $default-color;
  white-space: nowrap;
}

.footer-controls-input {
  width: 42px;
  height: 25px;
  padding: 5px;
  border-radius: 2px;
  box-shadow: 0 2px 7px 0 rgba(107, 97, 148, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: transparent;
  color: $default-color;
  text-align: center;
  margin: 0 8px;

  @media (min-width: $breakpoint-lg) {
    margin: 0 12px;
  }
}

.footer-controls-icon {
  width: 25px;
  height: 25px;
}

.footer-resize {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: 25px;
  width: 22px;

  padding: 5px;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: $default-color;
  cursor: default;

  &:focus {
    outline: none;
  }

  &:hover:not(:disabled) {
    cursor: pointer;
  }
}

.footer-controls-resize {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
}

.footer-controls-angles {
  min-height: 25px;
  margin: 0 10px;
  padding-left: 10px;
}

.footer-controls-angle {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: normal;
  color: $default-color;
  margin-left: 8px;
  cursor: pointer;

  &:first-child {
    margin-right: 8px;
  }

  @media (max-width: $breakpoint-lg) {
    margin-left: 0;
  }
}

.footer-controls-container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 5px;
}

.footer-controls-borders {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-controls-btn {
  min-width: 59px;
  height: 27px;
  padding: 5px;
  border-radius: 2px;
  box-shadow: 0 2px 7px 0 rgba(107, 97, 148, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: transparent;
  color: $default-color;
  font-size: 12px;
  font-weight: normal;

  &:focus {
    outline: none;
  }

  &:enabled:hover {
    cursor: pointer;
  }
}

.footer-settings {
  margin-top: 22px;
  padding: 0 4px;

  @media (min-width: $breakpoint-lg) {
    position: absolute;
    bottom: calc(100% + 20px);
    left: 10px;
    z-index: 12;
    padding: 17px 17px 15px 17px;
    min-width: 192px;
    margin-top: 0;

    @include themify($themes) {
      background-color: themed('btnSecondary');
    }
  }
}

.footer-settings-title {
  display: block;
  font-size: 12px;
  font-weight: 900;
  margin-bottom: 19px;

  @include themify($themes) {
    color: themed('text');
  }
}

.footer-settings-body {
  border-bottom: 1px solid;
  margin-bottom: 16px;

  @include themify($themes) {
    border-color: themed('cartBorder');
  }
}

.footer-settings-room {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-settings-subtitle {
  display: block;
  font-size: 12px;
  font-weight: 500;

  @include themify($themes) {
    color: themed('text');
  }

  &:last-child {
    font-size: 12px;
    color: #9e9faf;

    @media (min-width: $breakpoint-lg) {
      font-size: 10px;
    }
  }
}

.footer-settings-btn {
  height: 35px;
  font-size: 10px;
  font-weight: 900;
  min-height: unset;
  padding: 7px 10px;
  text-transform: uppercase;
  min-width: 88px;

  @include themify($themes) {
    background-color: themed('btnSecondary');
    color: themed('text');
  }

  @media (min-width: $breakpoint-lg) {
    height: 30px;
    font-size: 12px;
    font-weight: 500;
    text-transform: unset;
    min-width: unset;

    @include themify($themes) {
      background-color: themed('btnDefault');
    }
  }
}

.footer-zoom {
  max-width: 100%;
  min-width: 60px;
  margin-left: 10px;

  .rc-slider-rail {
    height: 2px;
    border-radius: unset;
  }

  .rc-slider-track {
    height: 2px;

    @include themify($themes) {
      background-color: themed('btnMain');
    }
  }

  .rc-slider-handle {
    width: 12px;
    height: 12px;
    background-color: $default-color;
    border: none;
    opacity: 1;
  }

  .rc-slider-handle.rc-slider-handle-dragging {
    border: unset;
    box-shadow: unset;
  }
}
