.popup {
  position: fixed;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  padding: 19px 19px 10px 19px;
  border-radius: 2px;
  z-index: 100000;
  width: 80%;

  @include themify($themes) {
    background-color: themed('popupBack');
  }

  @media (min-width: $breakpoint-md) {
    width: 300px;
  }
}

.popup-confirm {
  width: 90%;
  padding: 35px 25px;

  @media (min-width: $breakpoint-md) {
    max-width: 30%;
  }
}

.popup-close {
  position: absolute;
  background-color: transparent;
  border: none;
  top: 10px;
  right: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.popup-icon {
  width: 24px;
  height: 24px;
}

.popup-message {
  display: block;
  font-size: 16px;
  font-weight: 900;
  margin-top: 20px;
  margin-bottom: 33px;
  text-align: center;

  @include themify($themes) {
    color: themed('text');
  }

  .popup-confirm & {
    margin: 35px 0;
  }
}

.popup-img {
  display: block;
  height: 70px;
  object-fit: contain;
  margin: 0 auto;
  margin-top: 36px;
}

%popup-btn {
  width: 100%;
  font-size: 14px;
  text-align: center;
  padding: 10px 14px;
}

.popup-apply-btn {
  @extend %popup-btn;
  font-weight: 900;
}

.popup-cancel-btn {
  @extend %popup-btn;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @include themify($themes) {
    color: themed('popupBtnText');
  }
}

.popup-shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.4);
}
