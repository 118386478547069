.sidebar {
  background-color: $default-color;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  position: relative;

  @include themify($themes) {
    background-color: themed('sidebar');
  }

  @media (min-width: $breakpoint-lg) {
    max-width: 245px;
    margin: 0;
    height: calc(100vh - 50px);
  }

  @media (min-width: $breakpoint-xl) {
    padding-top: 14px;
    margin: 0;
    max-width: 310px;
    height: calc(100vh - 70px); //header plus bottom margin
  }
}

.sidebar-header-container {
  display: block;
  height: 87px;

  @media (min-width: $breakpoint-md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
  }

  @media (min-width: $breakpoint-lg) {
    height: auto;
  }
}

.sidebar-header {
  padding: 8px 0;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @media (min-width: $breakpoint-md) {
    display: block;
    padding: 15px 16px;
  }

  @media (min-width: $breakpoint-lg) {
    padding: 0 14px;
    border-bottom: 1px solid;

    @include themify($themes) {
      border-color: themed('sidebarBorder');
    }
  }

  @media (min-width: $breakpoint-xl) {
    padding: 0 20px;
  }
}

.sidebar-header-btn {
  padding: 5px;
}

.sidebar-header-icon {
  width: 14px;
  height: 14px;
  margin-right: 11px;

  @media (min-width: $breakpoint-lg) {
    width: 12px;
    height: 12px;
    margin-right: 7px;
  }

  @media (min-width: $breakpoint-xl) {
    width: 14px;
    height: 14px;
    margin-right: 11px;
  }
}

.sidebar-header-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid;
  padding-bottom: 13px;

  @include themify($themes) {
    border-color: themed('sidebarModuleBorder');
  }

  @media (min-width: $breakpoint-lg) {
    padding-top: 16px;
  }

  @media (min-width: $breakpoint-xl) {
    padding-top: 0;
  }
}

.sidebar-header-title {
  display: block;
  font-size: 12px;
  font-weight: 900;
  margin-right: 5px;

  @include themify($themes) {
    color: themed('text');
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 16px;
  }
}

.sidebar-header-controls {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 17px;
  margin: 13px 0;
}

.sidebar-body {
  padding: 2px 8px;
  overflow-y: auto;
  height: 140px;

  @media (min-width: $breakpoint-md) {
    padding: 0 16px;
    height: 120px;
  }

  @media (min-width: $breakpoint-lg) {
    height: auto;
    padding: 0 12px;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 0 20px;
  }
}

.sidebar-settings {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 6px;
  padding-bottom: 10px;

  & > * {
    color: $disable-color;
    width: 100%;
  }

  &.is-disabled {
    & * {
      color: $disable-color !important;
    }
  }

  &.is-active {
    & > * {
      color: $theme-color;
    }
  }

  @media (min-width: $breakpoint-md) {
    // padding-bottom: 23px;
    padding-bottom: 0;
  }

  @media (min-width: $breakpoint-lg) {
    padding: 6px 0;
    display: block;
    border-bottom: 1px solid;

    @include themify($themes) {
      border-color: themed('sidebarModuleBorder');
    }
  }

  @media (min-width: $breakpoint-xl) {
    padding: 9px 0;
  }

  &.is-open {
    padding-top: 0;
  }
}

.sidebar-settings-header {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  cursor: pointer;

  @media (min-width: $breakpoint-md) {
    display: none;
  }

  .sidebar-settings.is-open & {
    @media (min-width: $breakpoint-lg) {
      margin: 12px 0;
    }

    @media (min-width: $breakpoint-xl) {
      margin: 16px 0;
    }
  }

  @media (min-width: $breakpoint-lg) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 0;
  }
}

.sidebar-settings-checked {
  display: none;
  align-items: center;
  border: 1px solid;
  border-radius: 2px;
  padding: 8px 10px;
  font-size: 12px;
  font-weight: 500;

  @include themify($themes) {
    color: themed('text');
    border-color: themed('mainColor');
  }

  .sidebar-settings.is-open & {
    opacity: 0;
    z-index: -1;
  }

  @media (min-width: $breakpoint-lg) {
    display: flex;
    min-height: 32px;
  }
}

.sidebar-settings-price {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: $main-color;
  margin-left: 4px;
}

.sidebar-settings-title {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 10px;
  line-height: 1.2;

  @include themify($themes) {
    color: themed('text');
  }

  @media (min-width: $breakpoint-lg) {
    font-size: 11px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 14px;
  }
}

.sidebar-btn-category {
  display: none;
  font-size: 12px;
  font-weight: 900;

  @include themify($themes) {
    color: themed('text');
  }

  @media (min-width: $breakpoint-md) {
    display: block;
  }

  @media (min-width: $breakpoint-lg) {
    display: none;
  }
}

.sidebar-btn-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin: 9px 0;
  flex: 1;
  width: 100%;
  min-width: 100%;

  @media (min-width: $breakpoint-md) {
    grid-template-columns: unset;
    align-items: center;
    order: 1;
    display: inline-flex;
    flex: unset;
    width: auto;
    min-width: unset;
    margin-bottom: 22px;
  }

  @media (min-width: $breakpoint-lg) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex: unset;
    margin-bottom: 11px;
  }
}

.sidebar-btn {
  padding: 7px 14px;
  align-items: center;
  justify-content: center;
  border: 1px solid #c6c7d0;
  font-size: 11px;
  min-height: 30px;
  background-color: transparent;
  cursor: pointer;

  @include themify($themes) {
    color: themed('text');

    &:disabled {
      color: themed('textDisable');
    }
  }

  &:hover:not(:disabled) {
    background-color: transparent;
  }

  &.is-active {
    border: 1px solid $main-color;
  }

  &:disabled {
    background-color: transparent;
  }

  @media (min-width: $breakpoint-lg) {
    border-color: transparent;
    font-size: 12px;
    padding: 7px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 14px;
    min-height: 33px;
    padding: 7px 14px;
  }
}

/*-----------------------------------*/

.sidebar-footer {
  margin: 5px 0;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 1.6fr;
  gap: 8px;

  @include themify($themes) {
    border-color: themed('sidebarBorder');
    background-color: themed('sidebar');
  }

  @media (min-width: $breakpoint-md) {
    grid-template-columns: unset;
    gap: unset;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
    margin: 10px 0;
    padding: 8px 16px;
    border-top: 1px solid;
  }

  @media (min-width: $breakpoint-lg) {
    display: block;
    position: sticky;
    bottom: 0;
    left: 0;
    margin-top: auto;
    margin-bottom: 0;
    padding: 13px 15px;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 18px 20px;
    margin: 0;
    margin-top: auto;
  }
}

.sidebar-footer-total {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $breakpoint-md) {
    order: 2;
    margin-right: 24px;
    justify-content: space-between;
  }

  @media (min-width: $breakpoint-lg) {
    order: unset;
    margin-bottom: 14px;
    margin-right: 0;
  }
}

.sidebar-footer-title {
  display: block;
  font-size: 11px;
  font-weight: 900;
  text-transform: uppercase;

  @include themify($themes) {
    color: themed('text');
  }

  @media (min-width: $breakpoint-md) {
    font-size: 13px;
    margin-right: 8px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 14px;
    margin-right: 0;
  }
}

.sidebar-footer-price {
  display: block;
  font-size: 12px;
  font-weight: 900;

  @include themify($themes) {
    color: themed('text');
  }

  @media (min-width: $breakpoint-md) {
    font-size: 13px;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 16px;
  }
}

.sidebar-footer-cart {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $breakpoint-md) {
    order: 3;
    margin-bottom: 0;
  }

  @media (min-width: $breakpoint-lg) {
    order: unset;
    margin-bottom: 12px;
  }

  @media (min-width: $breakpoint-xl) {
    margin-bottom: 16px;
  }
}

.sidebar-cart-btn {
  height: 35px;
  min-height: unset;
  font-size: 10px;
  font-weight: 900;
  width: 100%;
  text-transform: uppercase;

  @media (min-width: $breakpoint-md) {
    height: 37px;
    padding: 0 25px;
    width: auto;
    text-transform: unset;
    font-size: 13px;
  }

  @media (min-width: $breakpoint-lg) {
    height: 34px;
    width: -webkit-fill-available;
    margin-left: 18px;
  }

  @media (min-width: $breakpoint-xl) {
    width: 66%;
    height: 40px;
    margin-left: 0;
  }
}

.sidebar-btn-expand {
  display: none;
  background-color: transparent;
  border: none;
  padding: 0 19px;
  width: 17px;
  height: 17px;
  position: absolute;
  right: 19px;
  top: 20px;

  @media (min-width: $breakpoint-md) {
    display: block;
  }

  @media (min-width: $breakpoint-lg) {
    display: none;
  }
}

.sidebar-icon-expand {
  width: 17px;
  height: 17px;
}

.sidebar-control-btn {
  border: none;
  background-color: transparent;
  position: relative;
  flex: 1;

  &:focus {
    outline: none;
  }

  &:first-child::after {
    content: '';
    position: absolute;
    top: 37%;
    left: 45px;
    width: calc(100vw - 100px);
    transform: translate(0, -50%);
    border-bottom: 4px dotted $border-color;

    @media (min-width: $breakpoint-md) {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #d8d8d8;
      border: none;
      transform: none;
      position: unset;
      display: block;
      margin-right: 8px;
    }
  }

  &:not(:last-child):after {
    @media (min-width: $breakpoint-md) {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #d8d8d8;
      display: block;
      margin-right: 8px;
    }
  }

  @media (min-width: $breakpoint-md) {
    display: inline-flex;
    align-items: center;
  }
}

.sidebar-control-icon.icon {
  width: 44px;
  height: 44px;
  margin: 0 auto;
  overflow: hidden;

  @media (min-width: $breakpoint-md) {
    display: none;
  }
}

.sidebar-control-title {
  display: block;
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 7px;

  @include themify($themes) {
    color: themed('text');
  }

  @media (min-width: $breakpoint-md) {
    font-size: 14px;
    font-weight: 900;
    color: $theme-color;
    text-transform: capitalize;
    margin-top: 0;
    margin-right: 8px;

    .sidebar-control-btn:disabled & {
      color: $disable-color;
    }

    .sidebar-control-btn.is-active & {
      color: #ffb800;
    }
  }
}

.sidebar-quantity {
  border: 1px solid;
  display: none;
  align-items: center;
  margin-right: 10px;

  @media (min-width: $breakpoint-md) {
    display: flex;
  }

  @include themify($themes) {
    border-color: themed('sidebarBorder');
  }

  @media (min-width: $breakpoint-lg) {
    margin-right: 0;
  }
}

.sidebar-quantity-btn,
.sidebar-quantity-input {
  height: 28px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  text-align: center;
  width: 20px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  @include themify($themes) {
    background-color: themed('gallery');
    color: themed('text');
  }

  @media (min-width: $breakpoint-md) {
    height: 35px;
  }

  @media (min-width: $breakpoint-lg) {
    height: 32px;
  }

  @media (min-width: $breakpoint-xl) {
    height: 38px;
    width: 26px;
  }
}

.sidebar-popup {
  position: fixed;
  padding: 26px 19px;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: calc(100% - 20px);
  z-index: 3;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid;

  @include themify($themes) {
    border-color: themed('galleryBorder');
    background-color: themed('gallery');
  }

  @media (min-width: $breakpoint-md) {
    top: 57px;
    right: 10px;
    width: 255px;
    transform: unset;
    padding: 26px 19px 10px 19px;
  }

  @media (min-width: $breakpoint-lg) {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 26px 15px 19px 10px;
    height: 100%;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 29px 28px 25px 21px;
  }
}

.sidebar-popup-title {
  display: block;
  font-size: 11px;
  font-weight: 900;
  margin-bottom: 15px;

  @include themify($themes) {
    color: themed('text');
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 14px;
  }
}

.sidebar-popup-body {
  display: flex;
  gap: 14px;
  margin-bottom: 20px;

  @media (min-width: $breakpoint-xl) {
    gap: 22px;
  }
}

.sidebar-popup-img {
  width: 66px;
  object-fit: contain;
  object-position: top;

  @media (min-width: $breakpoint-xl) {
    width: 80px;
  }
}

.sidebar-popup-text {
  display: block;
  font-size: 11px;
  font-weight: normal;
  line-height: 1.3;

  &.head {
    font-weight: bold;

    @media (min-width: $breakpoint-xl) {
      margin-bottom: 2px;
      line-height: 1.5;
    }
  }

  &.total {
    font-weight: bold;
    margin-top: auto;
    margin-top: 18px;

    @media (min-width: $breakpoint-xl) {
      margin-top: 15px;
    }
  }

  @include themify($themes) {
    color: themed('text');
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 12px;
  }
}

.sidebar-popup-btns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: auto;
  gap: 10px;
}

.sidebar-popup-btn {
  font-size: 11px;
  font-weight: 900;
  width: 100%;
  padding: 10px 15px;

  @media (min-width: $breakpoint-xl) {
    font-size: 14px;
  }
}

.sidebar-popup-close {
  position: absolute;
  top: 10px;
  right: 11px;
  width: 19px;
  height: 19px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.sidebar-control-value {
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 6px;
  border: 1px solid;
  margin-right: 8px;

  @include themify($themes) {
    color: themed('text');
    border-color: themed('btnMain');
  }
}
