.tooltip {
  display: block;
  position: relative;
  width: fit-content;
  text-align: center;
}

[data-tooltip] {
  position: relative;

  &::before,
  &::after {
    font-size: 14px;
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0;
  }

  &::before {
    content: '';
    border: 10px solid transparent;
    z-index: 5;
    bottom: calc(100% + 5px);
    border-bottom-width: 0;
    left: 50%;
    transform: translate(-50%, -0.5em);

    @include themify($themes) {
      border-top-color: themed('gallery');
    }
  }

  &::after {
    content: attr(data-tooltip);
    width: 260px;
    padding: 13px 15px;
    border-radius: 1px;
    z-index: 2;
    box-shadow: 0 0.2em 2em -0.5em rgba(0, 0, 0, 0.35);
    bottom: calc(100% + 12px);
    left: 110px;

    @include themify($themes) {
      background-color: themed('gallery');
      color: themed('text');
    }
  }

  // hover
  &:not(:disabled):hover {
    &::before,
    &::after {
      display: block;
    }
  }
}

[tooltip='']::before,
[tooltip='']::after {
  display: none;
}

@keyframes tooltips-vert {
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

[data-tooltip]:not([data-flow]):hover::before,
[data-tooltip]:not([data-flow]):hover::after {
  animation: tooltips-vert 200ms ease-out forwards;
}
