$main-color: #f15d3f;
$theme-color: #2c2e45;

$primary-color: #009bed;
$secondary-color: #f7f7f9;
$danger-color: #f21212;
$default-color: #ffffff;

$disable-color: #9e9faf;

$border-radius: 8px;
$border-color: #e8e8e8;

$back-light: #f7f7f9;
$back-dark: #1f2023;
$text-light: #2c2e45;
$text-disable-light: #9e9faf;
$text-disable-dark: #abacae;
$text-dark: #fff;
$link-light: #009bed;
$link-dark: #7dddff;

$content-border-light: #c7c7c7;
$content-border-dark: #383b40;
$content-back-light: #fff;
$content-back-dark: #27292d;
$content-btn-light: #c7c7c7;
$content-btn-dark: #abacae;
$content-text-light: #2c2e45;
$content-text-dark: #abacae;

$gallery-light: #fff;
$gallery-dark: #383b40;
$gallery-border-light: #eaeaea;
$gallery-border-dark: rgba(255, 255, 255, 0.08);

$footer-light: #2c2e45;
$footer-dark: #383b40;

$sidebar-light: #fff;
$sidebar-dark: #2d2f34;
$sidebar-border-light: #e8e8e8;
$sidebar-border-dark: #383b40;
$sidebar-module-border-light: rgba(234, 234, 234, 0.5);
$sidebar-module-border-dark: #383b40;

$product-border-light: #c6c7d0;
$product-border-dark: #aaaaad;

$product-shadow-ligth: #f58e79;
$product-shadow-dark: #ffb800;

$product-disable-light: #e6e6e6;
$product-disable-dark: #aaaaad;

$orientation-light: #f1f1f1;
$orientation-dark: #1f2023;

$table-th-light: #9b9caa;
$table-th-dark: #aaaaad;

$btn-main-light: #f15d3f;
$btn-main-dark: #f15d3f;
$btn-secodnary-light: #f7f7f9;
$btn-secodnary-dark: #383b40;
$btn-gallery-light: #f7f7f9;
$btn-gallery-dark: #484d56;
$btn-default-light: #fff;
$btn-default-dark: #2d2f34;

$cart-border-light: #e8e8e8;
$cart-border-dark: rgba(255, 255, 255, 0.08);

$switch-light: #cacbd0;
$switch-dark: #1f2023;

$radio-border-light: #dedede;
$radio-border-dark: #aaaaad;

$input-border-light: #e8e8e8;
$input-border-dark: #aaaaad;

$stepper-light: #dedede;
$stepper-dark: rgba(255, 255, 255, 0.08);

$stepper-label-light: #dedede;
$stepper-label-dark: #484d56;

$error-message-light: #f7d7db;
$error-message-dark: #d8b7bb;

$popup-back-light: #ffffff;
$popup-back-dark: #484d56;

$popup-btn-text-light: #2c2e45;
$popup-btn-text-dark: #aaaaad;

$colors-map: (
  main: $main-color,
  primary: $primary-color,
  secondary: $secondary-color,
  danger: $danger-color,
  default: $default-color,
);

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1440px;

$breakpoints: (
  md: 768px,
  lg: 1024px,
  xl: 1440px,
);

$container-max-widths: (
  md: 768px,
  lg: 1024px,
  xl: 1440px,
);
