.think-bubble {
  position: absolute;
  top: 38px;
  left: 0;
  width: 130px;
  background-color: #fff;
  padding: 10px;

  &:before{
    content: '';
    position: absolute;
    top: -8px;
    width: 20px;
    height: 17px;
    background-color: #ffffff;
    border: 2px solid red;
    border-bottom: 2px solid #ffffff;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    transform: rotate(-40.5deg);
  }

  &.border {
    border: 2px solid red;
  }
}