.order {
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  gap: 12px;
  padding: 28px 21px;
  border-bottom: 1px solid;
  position: relative;

  @include themify($themes) {
    border-color: themed('cartBorder');
  }
}

.order-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  overflow: auto;
  border-bottom: 1px solid;

  @include themify($themes) {
    border-color: themed('cartBorder');
  }
}

.order-img {
  width: 100%;
  object-fit: contain;
}

.order-title {
  display: block;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.21;
  margin-bottom: 8px;

  @include themify($themes) {
    color: themed('text');
  }
}

.order-text {
  display: block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;

  @include themify($themes) {
    color: themed('text');
  }
}

.order-btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 24px;
  right: 22px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  cursor: pointer;

  @include themify($themes) {
    background-color: themed('btnGallery');
  }
}

.order-icon {
  width: 14px;
}

.order-sum {
  display: block;
  margin-left: 14px;
  font-size: 12px;
  font-weight: 900;

  @include themify($themes) {
    color: themed('text');
  }
}

.order-counter {
  display: flex;
  align-items: center;
  margin-top: 22px;
}
